/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import TeamTitle from './teamTitle.component';
import TeamMember from './teamMember.component';

const StyledSection = styled.div`
  padding: 100px 0px 75px;

  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    padding: 75px 0px 50px;
  }
  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding: 50px 0px 35px;
  }
`;
const StyledContainer = styled.div`
  padding: 0px 60px;
  max-width: 1400px;
  margin: auto;

  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    padding: 0px 40px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding: 0px 20px;
  }
`;

const TeamMemberContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -15px;
  margin-right: -15px;

  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    display: block;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

export default function Cards({
  executiveData
}) {
  const {
    components,
    title: sectionTitle,
    childrenContentfulNimbleSectionDescriptionTextNode
  } = executiveData;

  return (
    <StyledSection background={executiveData.sectionBackground}>
      <StyledContainer>
        <TeamTitle
          title={sectionTitle}
          subtitle={childrenContentfulNimbleSectionDescriptionTextNode}
        />
        <TeamMemberContainer>
          {
            components.filter(item => item.__typename === 'ContentfulComponentBasic').map(({
              basicHeading,
              subheading,
              floatedImage,
              text
            }) => {
              return (
                <TeamMember
                  key={basicHeading}
                  heading={basicHeading}
                  subheading={subheading}
                  image={floatedImage}
                  bio={text}
                />
              );
            })
          }
        </TeamMemberContainer>
      </StyledContainer>
    </StyledSection>
  );
}

Cards.propTypes = {
  executiveData: PropTypes.shape({
    title: PropTypes.string,
    childrenContentfulNimbleSectionDescriptionTextNode: PropTypes.arrayOf(PropTypes.shape({
      description: PropTypes.string
    })),
    sectionBackground: PropTypes.string,
    components: PropTypes.arrayOf(PropTypes.shape({
      __typename: PropTypes.string,
      basicHeading: PropTypes.string,
      subheading: PropTypes.string,
      floatedImage: PropTypes.shape({
        title: PropTypes.string,
        file: PropTypes.objectOf(PropTypes.string)
      })
    }))
  })
};
