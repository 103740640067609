/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import Basic from '../../components/basic/index';
import Team from './components/team/team.component';
import VisionAndMission from './components/visionAndMission';
import CardsComponent from '../../components/cards';

const AboutUsWrapper = styled.div`
  text-align: center;
  .cards-card-background {
    background: ${props => props.theme.colours.pink};
  }

  .cards-text-container {
    color: ${props => props.theme.colours.nimbleRed};
    text-align: center;
    text-transform: uppercase;
  }

  .cards-text-heading {
    font-family: ${props => props.theme.font.nimbleBoldFont};
  }

  .cards-head-content {
    text-align: center;
  }

  .cards-heading {
    margin-bottom: 20px;
  }

  .cards-subheading {
    max-width: 820px;
    margin: auto;
  }
`;

const AboutUs = ({
  componentData
}) => {
  const basicData = componentData.filter(
    data => data.__typename === 'ContentfulComponentBasic'
  );
  const sectionData = componentData.filter(
    data => data.__typename === 'ContentfulNimbleSection'
  );
  const executiveData = sectionData.filter(
    data => data.sectionId === 'About Us - Executive Team'
  );
  const visionData = sectionData.filter(
    data => data.sectionId === 'Nimble Vision and Mission'
  );
  const valuesData = componentData.filter(
    data => data.__typename === 'ContentfulComponentCards'
  );
  return (
    <AboutUsWrapper>
      {
        basicData.map((item) => {
          return (
            <Basic
              key={item.basicHeading}
              data={item}
              timeline={item.componentId === 'Component - About Us - Timeline'}
            />
          );
        })
      }
      {!!executiveData.length && (
        <Team executiveData={executiveData[0]} />
      )}

      {!!visionData.length && (
        <VisionAndMission data={visionData[0]} />
      )}

      {!!valuesData.length && (
        <CardsComponent data={valuesData[0]} />
      )}
    </AboutUsWrapper>
  );
};

export default AboutUs;
