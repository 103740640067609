import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 50px;

  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    margin-bottom: 20px;
  }
`;
const StyledTitle = styled.h2`
  font-weight: 600;
  font-style: normal;
  margin-bottom: 15px;
`;

const StyledSubtitle = styled.h3`
  color: ${props => props.theme.colours.slate};
  font-size: 25px;
  line-height: 35px;
  font-weight: 400;
  font-style: normal;

  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    font-size: 18px;
    line-height: 24px;
  }
`;
const TeamTitle = ({
  title,
  subtitle,
  tooltipText
}) => (
  <TitleContainer>
    <StyledTitle>
      {title}
    </StyledTitle>
    {subtitle.length > 0 && tooltipText ? (
      <>
        <StyledSubtitle>
          {subtitle[0].description}
        </StyledSubtitle>

      </>
    ) : (
      null
    )}
    {subtitle.length > 0 && !tooltipText ? (
      <StyledSubtitle>
        {subtitle[0].description}
      </StyledSubtitle>
    ) : (
      null
    )}
  </TitleContainer>
);
TeamTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string
  })),
  tooltipText: PropTypes.objectOf(PropTypes.string)
};
export default TeamTitle;
