import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import RichText from '../../../../elements/richText';

const TeamMemberContainer = styled.div`
  flex: 0 0 33%;
  padding: 21px 15px;
  height: inherit;

  @media(max-width: ${props => props.theme.breakpoints.max.lg}){
    flex: 0 0 50%;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    max-width: 350px;
    margin: 0px auto 30px;
    padding: 15px 0px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const TeamMemberPhoto = styled.div`
  width: 250px;
  height: 250px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0px auto 40px;
  border-radius: 50%;

  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    margin-bottom: 30px;
  }
`;

const TeamMemberTitle = styled.h3`
  color: ${props => props.theme.colours.slate};
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  font-style: normal;
  text-align: center;
  margin-bottom: 10px;

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    font-size: 18px;
    line-height: 24px;
  }  
`;

const TeamMemberRole = styled.h4`
  font-size: 18px;
  color: #A8A8A8;
  text-align: center;
  margin-bottom: 20px;

  @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
    font-size: 16px;
    line-height: 20px;
  }  
`;

const TeamMemberBio = styled.div`
  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    text-align: center;
  }

  p {
    line-height: 28px;
    margin-top: 7px;
  }
`;

export default function CardItem({
  heading,
  subheading,
  image,
  bio
}) {
  return (
    <TeamMemberContainer>
      <TeamMemberPhoto role="img" style={{ backgroundImage: `url(${image.file.url})` }} aria-label={image.title} />

      <TeamMemberTitle>{heading}</TeamMemberTitle>
      {subheading ? (
        <TeamMemberRole>{subheading}</TeamMemberRole>
      ) : (
        null
      )}

      {bio && <TeamMemberBio><RichText text={bio} /></TeamMemberBio>}
    </TeamMemberContainer>
  );
}

CardItem.propTypes = {
  heading: PropTypes.string,
  subheading: PropTypes.string,
  image: PropTypes.shape({
    title: PropTypes.string,
    file: PropTypes.objectOf(PropTypes.string)
  }),
  bio: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
  ]))
};
