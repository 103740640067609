import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

const StyledSection = styled.div`
  padding: 75px 0;

  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    padding: 50px 0;
  }
  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding: 35px 0;
  }

  ${props => props.background === 'White' && css`
    background: #ffffff;
  `}

  ${props => props.background === 'Red' && css`
    background: ${props.theme.colours.nimbleRed};
  `}

  ${props => props.background === 'Pink' && css`
    background: ${props.theme.colours.pink};
  `}

  ${props => props.background === 'Grey' && css`
    background: ${props.theme.colours.greyBack};
  `}

  ${props => props.background === 'Cream' && css`
    background: ${props.theme.colours.cream};
  `}

  ${props => props.background === 'Green' && css`
    background: ${props.theme.colours.lightGreen};
  `}
`;
const StyledContainer = styled.div`
  padding: 0 60px;
  max-width: 1400px;
  margin: auto;
  text-align: center;

  @media(max-width: ${props => props.theme.breakpoints.max.md}){
    padding: 0 40px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding: 0 20px;
  }

  h2 {
    font-size: 24px;
    margin-bottom: 30px;

    &:last-child {
      margin-bottom: 0;
    }

    @media(max-width: ${props => props.theme.breakpoints.max.sm}) {
      font-size: 18px;
    }  
  }
`;

export default function VisionAndMission({ data }) {
  const {
    sectionBackground,
    childrenContentfulNimbleSectionDescriptionTextNode: text
  } = data;
  return (
    <StyledSection background={sectionBackground}>
      <StyledContainer>
        {
          text.map(({ description }) => {
            return (
              <ReactMarkdown>
                {description}
              </ReactMarkdown>
            );
          })
        }
      </StyledContainer>
    </StyledSection>
  );
}

VisionAndMission.propTypes = {
  data: PropTypes.shape({
    sectionBackground: PropTypes.string,
    childrenContentfulNimbleSectionDescriptionTextNode: PropTypes.arrayOf(PropTypes.shape({
      description: PropTypes.string
    }))
  })
};
